import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
import { Speedometer, Tools, ArtTools_01, CustomWorkloads } from '@carbon/pictograms-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Highlights`}</h2>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ResourceCard color="dark" title="Frameworks" actionIcon="arrowRight" href="/technologies/frameworks/" mdxType="ResourceCard">
    <ArtTools_01 style={{
            fill: "white"
          }} mdxType="ArtTools_01" />
    </ResourceCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ResourceCard color="dark" title="PHP refactoring" actionIcon="arrowRight" href="/programming/php/refactoring/" mdxType="ResourceCard">
    <Tools style={{
            fill: "white"
          }} mdxType="Tools" />
    </ResourceCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ResourceCard color="dark" title="PHP profiling" actionIcon="arrowRight" href="/programming/php/profiling/" mdxType="ResourceCard">
    <Speedometer style={{
            fill: "white"
          }} mdxType="Speedometer" />
    </ResourceCard>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ResourceCard color="dark" title="Kubernetes workloads" actionIcon="arrowRight" href="/devops/kubernetes/workloads/argo/" mdxType="ResourceCard">
    <CustomWorkloads style={{
            fill: "white"
          }} mdxType="CustomWorkloads" />
    </ResourceCard>
  </Column>
    </Row>
    <h2>{`Credits`}</h2>
    <p>{`This would not have been possible without:`}</p>
    <ul>
      <li parentName="ul">{`Gatsby for Static Site Generation* and optimized content delivery`}</li>
      <li parentName="ul">{`The beautiful library of Carbon Design System components`}</li>
      <li parentName="ul">{`Free pipeline runners and hosting on GitLab Pages`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      